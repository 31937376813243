import React from 'react';
import {Link} from 'react-router-dom'
import formicaCinerea from '../image/formica-cinerea.jpg';
import formicaCunicularia from '../image/formica-cunicularia.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica cunicularia",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica cunicularia",
    Nazwa_polska: "pierwomrówka ziemna",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "łąki, lasy, tereny piaszczyste",
    Wielkosc_krolowej: "8 - 9 mm",
    Wielkosc_robotnic: "6 - 7 mm",
    Kolor: "czarno - brązowy",
    Rojka: "czerwiec – koniec lipca",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Formica cunicularia.",
    Cechy_charakterystyczne: ["- średni odwłok", "- głowa, tarcza i odwłok ciemne", "- spód tułowia i nogi jaśniejsze", "- brązowe boki tułowia."],
    Ciekawostki: "Mrówka łatwo zakłada kolonię, robotnice są kolorystycznym urozmaiceniem wśród Formica spp.",
    Gatunki_podobne: ["Formica fusca", "Formica cinerea – czarna, tułów ciemny od spodu.", "Formica rufibarbis – różnica kolorów tułowia i odwłoka, rysunek na tarczy.", "Formica rufa - mniejszy odwłok, bardziej błyszcząca, więcej rudego koloru na tułowiu"],
    // Zachowanie: "Mrówki agresywne i terytorialne, posiadające bardzo dobry wzrok. Drobne zdobycze zazwyczaj transportują samotnie do gniazda. W przypadku dużego źródła pokarmu szybko i sprawnie rekrutują inne robotnice.",
    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    // Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    // Rozwoj_i_zimowanie: "Rozwój u tego gatunku jest bardzo szybki. Królowa rójkowa w ciągu miesiąca potrafi wychować pierwsze robotnice. Zimowanie bardzo dobrze wpływa na rozwój tych mrówek, jego brak może negatywnie wpłynąć na czerwienie królowej.",
    // Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaCunicularia} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus