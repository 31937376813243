import React from 'react';
import {Link } from 'react-router-dom'
import lasiusBrunneus from '../image/lasius-brunneus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Lasius brunneus",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius brunneus",
    Nazwa_polska: "hurtnica wstydliwa, hurtnica nadrzewna",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "lasy, parki, rzadziej miasta",
    Wielkosc_krolowej: "7 - 8 mm",
    Wielkosc_robotnic: "3 - 5 mm",
    Kolor: "brązowy, aksamitny",
    Rojka: "koniec maja - sierpień",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Lasius brunneus",
    Cechy_charakterystyczne: [" - aksamitny połysk", "- wcięty tył głowy", "- brunatne odnóża", "- czekoladowy tułów i odwłok", "- głowa szerokości tułowia."],
    Ciekawostki: "Łatwa do pomylenia z Lasius niger, jednak po kilku różnicach da się je odróżnić, zwłaszcza mając Lasius niger do porównania. Chętnie gniazduje w drewnie.",
    Gatunki_podobne: ["Lasius niger – nieco ciemniejsza, masywniejsza, tułów szerszy niż u L. brunneus, odwłok ciemniejszy i większy w stosunku do ciała niż u L. brunneus.",
    "Lasius emarginatus – różni się kolorem tułowia i delikatnym rysunkiem; masywniejsza, szerszy tułów.",
    "Lasius flavus – żółte odnóża, masywniejsza, głowa węższa od tułowia.",
    "Lasius umbratus – większa głowa, szersza od tułowia; bardziej wcięta tylna krawędź głowy, dużo mniejszy odwłok."],

    Miejsce_gniazdowania: "Lasius brunneus najczęściej zakłada kolonie w spróchniałym drzewie (są dendrofilami). Czasami kolonie tego gatunku możemy znaleźć w szczelinach budynków, lub nawet we wnętrzu domu (chętnie gnieżdżą się w drewnianej podłodze, często wykorzystują różne szczeliny).",
    Zachowanie: "Bardzo płochliwa i ostrożna mrówka, unikająca otwartych przestrzeni. Do dużych źródeł pokarmu tworzy bardzo wyraźne i zwarte szlaki zapachowe (charakterystyczne zachowanie tego gatunku). Lasius brunneus unika konfrontacji z innymi gatunkami mrówek, lecz czasami atakuje inne kolonie tego samego gatunku.",
    Polimorfizm: "Brak polimorfizmu, gatunek monomorficzny.",
    Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym, zazwyczaj unika walki ratując się ucieczką."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusBrunneus} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus