import React from 'react';
import {Link} from 'react-router-dom'
import dolichoderusQuadripunctatus from '../image/dolichoderus-quadripunctatus.jpg';
import leptothoraxAcervorum from '../image/leptothorax-acervorum.jpg';
import manicaRubida from '../image/manica-rubida.jpg';
import myrmicaRubra from '../image/myrmica-rubra.jpg';
import polyergusRufescens from '../image/polyergus-rufescens.jpg';
import poneraCoarctata from '../image/ponera-coarctata.jpg';
import solenopsisFugax from '../image/solenopsis-fugax.jpg';
import strongylognathusTestaceus from '../image/strongylognathus-testaceus.jpg';
import tapinomaErraticum from '../image/tapinoma-erraticum.jpg';
import temnothoraxCrassispinus from '../image/temnothorax-crassispinus.jpg';
import tetramoriumCaespitum from '../image/tetramorium-caespitum.jpg';

import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Solenopsis fugax",
    Rodzaj: "Solenopsis",
    Nazwa_lacinska: "Solenopsis fugax",
    Nazwa_polska: "mrówka złodziejka",
    Czestosc_wystepowania: "3/5",
    Wystepowanie: "miasta, łąki, lasy, darnie",
    Wielkosc_krolowej: "5 - 7 mm",
    Wielkosc_robotnic: "1,5 - 2 mm",
    Kolor: "czarny, błyszczący; żółtawy spód odwłoka",
    Rojka: "połowa sierpnia – koniec września",
    Zakladanie_gniazda: "klasztorny, po przezimowaniu",
    Adopcja: "W przypadku osierocenia - Solenopsis fugax.",
    Cechy_charakterystyczne: ["- tułów i głowa dużo węższe od odwłoka,",
        "- jasne odnóża."],
    Ciekawostki: "Należy obserwować, czy królowa w ciągu kilku dni od złapania zaczęła czerwić - jeśli nie, może wymagać przezimowania (jest to spowodowane późną rójką tego gatunku). Gatunek praktykuje lestobiozę w gniazdach hurtnic – specjalnie ukształtowane formikarium pozwala na obserwacje podjadania potomstwa. Gniazdo musi posiadać dwie części połączone bardzo cienkimi korytarzami oraz oddzielne areny dla złodziejek i hurtnic. W hodowli są wybitnie mięsożerne.",
    Gatunki_podobne: ["Tetramorium caespitum – głowa i tułów nieznacznie węższe od odwłoka, ciemniejsze odnóża, inny termin rójki."],
    // Trudnosc_hodowli: "Królowe są bardzo agresywne, co utrudnia adopcję (patrz: Strategia hodowli). Poza tym, gatunek łatwy w hodowli - rozwija się bardzo szybko - po kilku latach hodowli może to być problematyczne.",
    // Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    // Miejsce_gniazdowania: "lasy, zwłaszcza iglaste, często na obrzeżach; gniazda w spróchniałych pniakach (głównie), trawie, pod drzewami, także kamieniami (szczególnie w górach), tereny suche, nasłonecznione obrzeża lasu",
    // Zachowanie: "Robotnice polują samotnie, gdy poczują się zagrożone bardzo szybko uciekają. Unikają konfrontacji z innymi mrówkami.",
    // Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    // Polimorfizm: "Występują wyraźne kasty (polimorfizm).",
    Liczebnosc: "Dorosłe kolonie mogą liczyć 100 000 robotnic.",
    // Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    Rozwoj_i_zimowanie: "Rójkowa królowa zazwyczaj bardzo długo wychowuje pierwsze robotnice i wymaga zimowania (zdarzają się wyjątki). Kiedy kolonia liczy kilkanaście robotnic rozwój znacznie przyspiesza, matka zaczyna bardzo intensywnie czerwić.",
    // Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    // Metody_obrony: "Camponotus fallax zazwyczaj unika walki ratując się ucieczką. Mrówki tego gatunku potrafią pryskać kwasem.",
    // Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    // Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    // Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    //Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={solenopsisFugax} alt="Opis gatunku Dolichoderus quadripunctatus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus