import React from 'react';
import {Link} from 'react-router-dom'
import lasiusEmarginatus from '../image/lasius-emarginatus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Lasius emarginatus",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius emarginatus",
    Nazwa_polska: "hurtnica skalna",
    Czestosc_wystepowania: "3/5",
    Wystepowanie: "lasy, parki, miasta",
    Wielkosc_krolowej: "7 - 9 mm",
    Wielkosc_robotnic: "3 - 5 mm",
    Kolor: "brązowy, aksamitny",
    Rojka: "koniec czerwca - początek sierpnia",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Lasius emarginatus.",
    Cechy_charakterystyczne: ["- tułów bardziej brązowy i jaśniejszy od odwłoka i głowy, czasami z delikatnym rysunkiem", "- aksamitny połysk", "- wcięty tył głowy", "- brunatne odnóża."],
    Ciekawostki: " Robotnice mają interesujące ubarwienie, poza tym są szybsze i agresywniejsze od Lasius niger. Gniazduje w drewnie.",
    Gatunki_podobne: ["Lasius niger – ciemniejszy tułów, brak różnic w kolorze tułowia i reszty ciała, nieco węższa głowa.", 
        "Lasius brunneus – nieco jaśniejsza, smuklejsza i drobniejsza, brak różnic w kolorze tułowia i reszty ciała."],
    Miejsce_gniazdowania: "",
    Zachowanie: "",
    Polimorfizm: "",
    Metody_obrony: ""
}


const LasiusEmarginatus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusEmarginatus} alt="Opis gatunku Lasius emarginatus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusEmarginatus