import React from 'react';

const WspolnySzablonGatunku = (props) => {
    const {daneMrowki} = props;
    return (
        <>
            <h1>{daneMrowki.Naglowek}</h1>
            <dt>Rodzaj</dt>                     <dd>{daneMrowki.Rodzaj}</dd>
            <dt>Nazwa łacińska</dt>             <dd style={{fontStyle: "italic" }}>{daneMrowki.Nazwa_lacinska}</dd>
            <dt>Nazwa polska</dt>               <dd>{daneMrowki.Nazwa_polska}</dd>
            <dt>Częstość występowania</dt>      <dd>{daneMrowki.Czestosc_wystepowania}</dd>
            <dt>Występowanie</dt>               <dd>{daneMrowki.Wystepowanie}</dd>
            <dt>Wielkość królowej</dt>          <dd>{daneMrowki.Wielkosc_krolowej}</dd>
            <dt>Wielkość robotnic</dt>          <dd>{daneMrowki.Wielkosc_robotnic}</dd>
            <dt>Kolor</dt>                      <dd>{daneMrowki.Kolor}</dd>
            <dt>Rójka</dt>                      <dd>{daneMrowki.Rojka}</dd>
            <dt>Zakładanie gniazda</dt>         <dd>{daneMrowki.Zakladanie_gniazda}</dd>
            <dt>Adopcja</dt>                    <dd>{daneMrowki.Adopcja}</dd>
            <dt>Cechy charakterystyczne</dt>    <dd>{daneMrowki.Cechy_charakterystyczne.map( (el) => (<div> {el} </div>) ) }</dd>
            <dt>Ciekawostki</dt>                <dd>{daneMrowki.Ciekawostki}</dd>
            
            {daneMrowki.Gatunki_podobne && 
                <> <dt>Gatunki podobne</dt> <dd>{daneMrowki.Gatunki_podobne.map( (el) => (<div> {el} </div>) ) }</dd> </>}
            {daneMrowki.Trudnosc_hodowli && 
                <> <dt>Trudność hodowli</dt> <dd>{daneMrowki.Trudnosc_hodowli}</dd> </>}
            {daneMrowki.Strategia_hodowli && 
                <> <dt>Strategia hodowli</dt> <dd>{daneMrowki.Strategia_hodowli}</dd> </>}
            {daneMrowki.Miejsce_gniazdowania && 
                <> <dt>Miejsce gniazdowania</dt> <dd>{daneMrowki.Miejsce_gniazdowania}</dd> </>}
            {daneMrowki.Zachowanie && 
                <> <dt>Zachowanie</dt> <dd>{daneMrowki.Zachowanie}</dd> </>}
            {daneMrowki.Zachowanie_krolowych_w_kolonii && 
                <> <dt>Zachowanie królowych</dt> <dd>{daneMrowki.Zachowanie_krolowych_w_kolonii}</dd> </>}
            {daneMrowki.Polimorfizm && 
                <> <dt>Polimorfizm</dt> <dd>{daneMrowki.Polimorfizm}</dd> </>}
            {daneMrowki.Liczebnosc && 
                <> <dt>Liczebnosc</dt> <dd>{daneMrowki.Liczebnosc}</dd> </>}
            {daneMrowki.Wilgotnosc_i_temperatura && 
                <> <dt>Wilgotność i temperatura</dt> <dd>{daneMrowki.Wilgotnosc_i_temperatura}</dd> </>}
            {daneMrowki.Rozwoj_i_zimowanie && 
                <> <dt>Rozwój i zimowanie</dt> <dd>{daneMrowki.Rozwoj_i_zimowanie}</dd> </>}
            {daneMrowki.Wyglad_gniazda && 
                <> <dt>Wygląd gniazda</dt> <dd>{daneMrowki.Wyglad_gniazda}</dd> </>}
            {daneMrowki.Metody_obrony && 
                <> <dt>Metody obrony</dt> <dd>{daneMrowki.Metody_obrony}</dd> </>}
            {daneMrowki.Aktywnosc_i_zasięg && 
                <> <dt>Aktywność i zasięg</dt> <dd>{daneMrowki.Aktywnosc_i_zasięg}</dd> </>}
            {daneMrowki.Klasyfikacja_systematyczna && 
                <> <dt>Klasyfikacja systematyczna</dt> <dd>{daneMrowki.Klasyfikacja_systematyczna}</dd> </>}
            {daneMrowki.Gatunki_blizniacze && 
                <> <dt>Gatunki bliźniacze</dt> <dd>{daneMrowki.Gatunki_blizniacze}</dd> </>}
            {daneMrowki.Oprzed_poczwarek && 
                <> <dt>Oprzęd poczwarek</dt> <dd>{daneMrowki.Oprzed_poczwarek}</dd> </>}
            {daneMrowki.Ochrona && 
                <> <dt>Ochrona</dt> <dd>{daneMrowki.Ochrona}</dd> </>}
            {daneMrowki.Zrodla && 
                <> <br/> <dt>Źródła</dt> <dd>{daneMrowki.Zrodla}</dd> </>}
        </>
    )
}

export default WspolnySzablonGatunku