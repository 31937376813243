import React from 'react';
import {BrowserRouter, Link, Routes, Route, Navigate} from 'react-router-dom';
import Page from './Page';
import MenuMrowkowe from './Menu_mrowkowe';

//podstrony
import LasiusBrunneus from '../gatunek/lasius-brunneus';
import LasiusEmarginatus from '../gatunek/lasius-emarginatus';
import LasiusFlavus from '../gatunek/lasius-flavus';
import LasiusFuliginosus from '../gatunek/lasius-fuliginosus';
import LasiusNiger from '../gatunek/lasius-niger';
import LasiusUmbratus from '../gatunek/lasius-umbratus';
import FormicaCinerea from '../gatunek/formica-cinerea';
import FormicaCunicularia from '../gatunek/formica-cunicularia';
import FormicaFusca from '../gatunek/formica-fusca';
import FormicaRufa from '../gatunek/formica-rufa';
import FormicaRufibarbis from '../gatunek/formica-rufibarbis';
import FormicaSanguinea from '../gatunek/formica-sanguinea';
import FormicaTruncorum from '../gatunek/formica-truncorum';
import CamponotusFallax from '../gatunek/camponotus-fallax';
import CamponotusHerculeanus from '../gatunek/camponotus-herculeanus';
import CamponotusLigniperda from '../gatunek/camponotus-ligniperda';
import DolichoderusQuadripunctatus from '../gatunek/dolichoderus-quadripunctatus';
import LeptothoraxAcervorum from '../gatunek/leptothorax-acervorum';
import ManicaRubida from '../gatunek/manica-rubida';
import MyrmicaRubra from '../gatunek/myrmica-rubra';
import PolyergusRufescens from '../gatunek/polyergus-rufescens';
import PoneraCoarctata from '../gatunek/ponera-coarctata';
import SolenopsisFugax from '../gatunek/solenopsis-fugax';
import StrongylognathusTestaceus from '../gatunek/strongylognathus-testaceus';
import TapinomaErraticum from '../gatunek/tapinoma-erraticum';
import TemnothoraxCrassispinus from '../gatunek/temnothorax-crassispinus';
import TetramoriumCaespitum from '../gatunek/tetramorium-caespitum';


function App() {
    return (
        <BrowserRouter>
            <div className="main">
                <section className="content">
                    <Routes>
                        <Route exact path="/" element= { <Navigate to="/gatunek" /> } /> 
                        <Route exact path="/gatunek" element={ <MenuMrowkowe/> } />
                        <Route path="/gatunek/lasius-brunneus" element={ <LasiusBrunneus/> } />
                        <Route path="/gatunek/lasius-emarginatus" element={ <LasiusEmarginatus/> } />
                        <Route path="/gatunek/lasius-flavus" element={ <LasiusFlavus/> } />
                        <Route path="/gatunek/lasius-fuliginosus" element={ <LasiusFuliginosus/> } />
                        <Route path="/gatunek/lasius-niger" element={ <LasiusNiger/> } />
                        <Route path="/gatunek/lasius-umbratus" element={ <LasiusUmbratus/> } />
                        <Route path="/gatunek/formica-cinerea" element={ <FormicaCinerea/> } />
                        <Route path="/gatunek/formica-cunicularia" element={ <FormicaCunicularia/> } />
                        <Route path="/gatunek/formica-fusca" element={ <FormicaFusca/> } />
                        <Route path="/gatunek/formica-rufa" element={ <FormicaRufa/> } />
                        <Route path="/gatunek/formica-rufibarbis" element={ <FormicaRufibarbis/> } />
                        <Route path="/gatunek/formica-sanguinea" element={ <FormicaSanguinea/> } />
                        <Route path="/gatunek/formica-truncorum" element={ <FormicaTruncorum/> } />
                        <Route path="/gatunek/camponotus-fallax" element={ <CamponotusFallax/> } />
                        <Route path="/gatunek/camponotus-herculeanus" element={ <CamponotusHerculeanus/> } />
                        <Route path="/gatunek/camponotus-ligniperda" element={ <CamponotusLigniperda/> } />
                        <Route path="/gatunek/dolichoderus-quadripunctatus" element={ <DolichoderusQuadripunctatus/> } />
                        <Route path="/gatunek/leptothorax-acervorum" element={ <LeptothoraxAcervorum/> } />
                        <Route path="/gatunek/manica-rubida" element={ <ManicaRubida/> } />
                        <Route path="/gatunek/myrmica-rubra" element={ <MyrmicaRubra/> } />
                        <Route path="/gatunek/polyergus-rufescens" element={ <PolyergusRufescens/> } />
                        <Route path="/gatunek/ponera-coarctata" element={ <PoneraCoarctata/> } />
                        <Route path="/gatunek/solenopsis-fugax" element={ <SolenopsisFugax/> } />
                        <Route path="/gatunek/strongylognathus-testaceus" element={ <StrongylognathusTestaceus/> } />
                        <Route path="/gatunek/tapinoma-erraticum" element={ <TapinomaErraticum/> } />
                        <Route path="/gatunek/temnothorax-crassispinus" element={ <TemnothoraxCrassispinus/> } />
                        <Route path="/gatunek/tetramorium-caespitum" element={ <TetramoriumCaespitum/> } />
                    </Routes>
                    {/* <Page/> */}
                </section>

                <footer className="footer">
                    <Routes>
                        {/* <Route path="/" exact element={ <LasiusBrunneus/> } /> */}
                    </Routes>

                </footer>
            </div>
        </BrowserRouter>
    );
}
    
export default App;