import React from 'react';
import {Link} from 'react-router-dom'
import dolichoderusQuadripunctatus from '../image/dolichoderus-quadripunctatus.jpg';
import leptothoraxAcervorum from '../image/leptothorax-acervorum.jpg';
import manicaRubida from '../image/manica-rubida.jpg';
import myrmicaRubra from '../image/myrmica-rubra.jpg';
import polyergusRufescens from '../image/polyergus-rufescens.jpg';
import poneraCoarctata from '../image/ponera-coarctata.jpg';
import solenopsisFugax from '../image/solenopsis-fugax.jpg';
import strongylognathusTestaceus from '../image/strongylognathus-testaceus.jpg';
import tapinomaErraticum from '../image/tapinoma-erraticum.jpg';
import temnothoraxCrassispinus from '../image/temnothorax-crassispinus.jpg';
import tetramoriumCaespitum from '../image/tetramorium-caespitum.jpg';

import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Tapinoma erraticum",
    Rodzaj: "Tapinoma",
    Nazwa_lacinska: "Tapinoma erraticum",
    Nazwa_polska: "koczowniczka czarna",
    Czestosc_wystepowania: "2/5",
    Wystepowanie: "Pojedyncze miejsca występowania znajdują się na wyżynach oraz na Mazowszu, wszędzie bardzo rzadka.",
    Wielkosc_krolowej: "5 - 6 mm",
    Wielkosc_robotnic: "3 - 4 mm",
    Kolor: "ciemnoszara",
    Rojka: "czerwiec - lipiec",
    Zakladanie_gniazda: "Gatunek klasztorny.",
    Adopcja: "Zalecana adopcja poczwarek lub robotnic do samotnej królowej.",
    Cechy_charakterystyczne: ["Budową ciała przypomina Lasius brunneus, ma jednak inny kształt głowy, jest ciemniejsza, dużo mniejsza i nie posiada łuski pomostkowej."],
    Ciekawostki: "Bardzo rzadka mrówka, poliginiczna. Poczwarki nigdy nie wytwarzają oprzędu, dzięki czemu gniazdo można odróżnić od gniazda stosunkowo podobnych, aczkolwiek nieco większych Lasius niger.",
    Gatunki_podobne: ["Lasius brunneus – dużo większa, brązowa, posiada łuskę pomostka."],
    Trudnosc_hodowli: "Gatunek umiarkowanie trudny w hodowli - mrówki są wrażliwe na wahania wilgotności, szczególnie jej nadmiar. Jeżeli warunki nie są odpowiednie, zjadają potomstwo.  Należy pamiętać o podawaniu drobnych owadów (podstawa diety), np. muszek owocowych.",
    Strategia_hodowli: "Najprościej rozpocząć hodowlę przez odłowienie królowej i orszaku robotnic z poliginicznego gniazda, np. umieszczonego pod kamieniem. W przypadku rójkowych królowych: wychów klasztorny, preferowane połączenie wielu królowych lub adopcja.",
    Miejsce_gniazdowania: "Nasłonecznione i suche tereny, łąki, darnie, nasłonecznione zbocza; często spotykana na terenach o podłożu wapiennym.",
    Zachowanie: "Umiarkowanie agresywne, polują na bezkręgowce.",
    Zachowanie_krolowych_w_kolonii: "Gatunek poliginiczny, w gnieździe może być nawet kilkanaście czerwiących królowych.",
    // Polimorfizm: "Występują wyraźne kasty (polimorfizm).",
    Liczebnosc: "Kilkaset do kilku tysięcy robotnic.",
    Wilgotnosc_i_temperatura: "Niska wilgotność, ciepłolubna (kserofil, termofil).",
    Rozwoj_i_zimowanie: "Małe kolonie rozwijają się powoli, szczególnie bez dogrzewania.  Można zimować, aby skłonić królowe do czerwienia.",
    Wyglad_gniazda: "Gniazda są zbudowane z systemu korytarzy pod ziemią, często pod kamieniami; czasem występuje niewielki kopiec.",
    Metody_obrony: "Mrówki nie posiadają żądła - w razie potrzeby spryskują przeciwnika kwasem, jednak robią to niechętnie.",
    Aktywnosc_i_zasięg: "Robotnice są aktywne w słoneczne dni; hodują mszyce, chętnie polują na drobne bezkręgowce, które są podstawą ich diety.",
    Klasyfikacja_systematyczna: "Dolichoderinae, Tapinomini, Tapinoma",
    //Gatunki_blizniacze: "Tapinoma ambiguum - niezwykle rzadki gatunek, znaleziony w Pieninach; bardzo podobny do Tapinoma erraticum.",
    // Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    Zrodla: '"The ants (Hymenoptera, Formicidae) of Poland" - W. Czechowski, A. Radchenko, W. Czechowska, 2002, Warszawa',
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={tapinomaErraticum} alt="Opis gatunku Tapinoma erraticum"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus