import React from 'react';
import {Link} from 'react-router-dom'
import formicaRufibarbis from '../image/formica-rufibarbis.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica rufibarbis",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica rufibarbis",
    Nazwa_polska: "pierwomrówka krasnolica",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "lasy",
    Wielkosc_krolowej: "9 - 10 mm",
    Wielkosc_robotnic: "5 - 8 mm",
    Kolor: "brązowo-czarny",
    Rojka: "koniec czerwca - koniec sierpnia",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Formica rufibarbis lub Formica fusca.",
    Cechy_charakterystyczne: [" duży odwłok,", '- charakterystyczny rysunek na tułowiu w kształcie litery "M",', "- różnica w kolorze tułowia i odwłoka."],
    Ciekawostki: "Mrówka cenna ze względu na walory kolorystyczne robotnic i łatwość zakładania kolonii. Pierwsze robotnice są z reguły czarne, a kolorowe mrówki pojawiają się stosunkowo późno.",
    Gatunki_podobne: ["Formica sanguinea - czarna, szeroka głowa, mniejszy odwłok, inny rysunek na tułowiu.",
        "Formica cunicularia - wyraźnie ciemniejsza, czarna tarcza."],
    // Zachowanie: "Mrówki agresywne i terytorialne, posiadające bardzo dobry wzrok. Drobne zdobycze zazwyczaj transportują samotnie do gniazda. W przypadku dużego źródła pokarmu szybko i sprawnie rekrutują inne robotnice.",
    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    Liczebnosc: "Kolonia może liczyć kilkaset robotnic.",
    // Rozwoj_i_zimowanie: "Rozwój u tego gatunku jest bardzo szybki. Królowa rójkowa w ciągu miesiąca potrafi wychować pierwsze robotnice. Zimowanie bardzo dobrze wpływa na rozwój tych mrówek, jego brak może negatywnie wpłynąć na czerwienie królowej.",
    // Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym.",
    //Ochrona: "Mrówka znajduje się pod częściową ochroną.",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaRufibarbis} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus