import React from 'react';
import {BrowserRouter, Link, Routes, Route} from 'react-router-dom'

// obrazki
import lasiusBrunneus from '../image/lasius-brunneus.jpg';
import lasiusEmarginatus from '../image/lasius-emarginatus.jpg';
import lasiusFlavus from '../image/lasius-flavus.jpg';
import LasiusFuliginosus from '../image/lasius-fuliginosus.jpg';
import lasiusNiger from '../image/lasius-niger.jpg';
import lasiusUmbratus from '../image/lasius-umbratus.jpg';
import formicaCinerea from '../image/formica-cinerea.jpg';
import formicaCunicularia from '../image/formica-cunicularia.jpg';
import formicaFusca from '../image/formica-fusca.jpg';
import formicaRufa from '../image/formica-rufa.jpg';
import formicaRufibarbis from '../image/formica-rufibarbis.jpg';
import formicaSanguinea from '../image/formica-sanguinea.jpg';
import formicaTruncorum from '../image/formica-truncorum.jpg';
import camponotusFallax from '../image/camponotus-fallax.jpg';
import camponotusHerculeanus from '../image/camponotus-herculeanus.jpg';
import camponotusLigniperda from '../image/camponotus-ligniperda.jpg';
import dolichoderusQuadripunctatus from '../image/dolichoderus-quadripunctatus.jpg';
import leptothoraxAcervorum from '../image/leptothorax-acervorum.jpg';
import manicaRubida from '../image/manica-rubida.jpg';
import myrmicaRubra from '../image/myrmica-rubra.jpg';
import polyergusRufescens from '../image/polyergus-rufescens.jpg';
import poneraCoarctata from '../image/ponera-coarctata.jpg';
import solenopsisFugax from '../image/solenopsis-fugax.jpg';
import strongylognathusTestaceus from '../image/strongylognathus-testaceus.jpg';
import tapinomaErraticum from '../image/tapinoma-erraticum.jpg';
import temnothoraxCrassispinus from '../image/temnothorax-crassispinus.jpg';
import tetramoriumCaespitum from '../image/tetramorium-caespitum.jpg';


const MenuMrowkowe = () => {
    return (
        <div className="wybierz_mrowke">

            <nav className="naglowek">
                <h1>Atlas mrówek</h1>
                Strona stworzona dla pasjonatów mrówek - przez pasjonatów mrówek.
                <br/>
                {/* <button className="naglowek__button">Spis wszytkich mrówek</button>
                <button className="naglowek__button">Kreator wyszukiwania mrówki</button> */}
            </nav>

            <h2>Lasius</h2>
                            
            <Link to="lasius-brunneus" className="kafelek">
                <img src={lasiusBrunneus} alt="Opis gatunku Lasius brunneus"/>
                <div>Lasius brunneus</div>
            </Link>

            <Link to="lasius-emarginatus" className="kafelek">
                <img src={lasiusEmarginatus}/>
                <div>Lasius emarginatus</div>
            </Link>

            <Link to="lasius-flavus" className="kafelek">
                    <img src={lasiusFlavus}/>
                    <div>Lasius flavus</div>
            </Link>

            <Link to="lasius-fuliginosus" className="kafelek">
                <img src={LasiusFuliginosus} alt="Save icon"/>
                <div>Lasius fuliginosus</div> 
            </Link>

            <Link to="lasius-niger" className="kafelek">
                <img src={lasiusNiger} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>Lasius niger</div> 
            </Link>

            <Link to="lasius-umbratus" className="kafelek">
                <img src={lasiusUmbratus} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>Lasius umbratus</div> 
            </Link>
            <h2>Formica</h2>
            <Link to="formica-cinerea" className="kafelek">
                <img src={formicaCinerea} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica cinerea</div> 
            </Link>

            <Link to="formica-cunicularia" className="kafelek">
                <img src={formicaCunicularia} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica cunicularia</div> 
            </Link>

            <Link to="formica-fusca" className="kafelek">
                <img src={formicaFusca} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica fusca</div> 
            </Link>

            <Link to="formica-rufa" className="kafelek">
                <img src={formicaRufa} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica rufa</div> 
            </Link>

            <Link to="formica-rufibarbis" className="kafelek">
                <img src={formicaRufibarbis} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica rufibarbis</div> 
            </Link>

            <Link to="formica-sanguinea" className="kafelek">
                <img src={formicaSanguinea} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica sanguinea</div> 
            </Link>

            <Link to="formica-truncorum" className="kafelek">
                <img src={formicaTruncorum} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>formica truncorum</div> 
            </Link>

            <h2>Camponotus</h2>
            <Link to="camponotus-fallax" className="kafelek">
                <img src={camponotusFallax} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>camponotus fallax</div> 
            </Link>

            <Link to="camponotus-herculeanus" className="kafelek">
                <img src={camponotusHerculeanus} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>camponotus herculeanus</div> 
            </Link>

            <Link to="camponotus-ligniperda" className="kafelek">
                <img src={camponotusLigniperda} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>camponotus ligniperda</div> 
            </Link>

            <h2>Inne</h2>
            <Link to="dolichoderus-quadripunctatus" className="kafelek">
                <img src={dolichoderusQuadripunctatus} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>dolichoderus quadripunctatus</div> 
            </Link>

            <Link to="leptothorax-acervorum" className="kafelek">
                <img src={leptothoraxAcervorum} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>leptothorax acervorum</div> 
            </Link>

            <Link to="manica-rubida" className="kafelek">
                <img src={manicaRubida} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>manica rubida</div> 
            </Link>

            <Link to="myrmica-rubra" className="kafelek">
                <img src={myrmicaRubra} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>myrmica rubra</div> 
            </Link>

            <Link to="polyergus-rufescens" className="kafelek">
                <img src={polyergusRufescens} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>polyergus rufescens</div> 
            </Link>

            <Link to="ponera-coarctata" className="kafelek">
                <img src={poneraCoarctata} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>ponera coarctata</div> 
            </Link>

            <Link to="solenopsis-fugax" className="kafelek">
                <img src={solenopsisFugax} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>solenopsis fugax</div> 
            </Link>

            <Link to="strongylognathus-testaceus" className="kafelek">
                <img src={strongylognathusTestaceus} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>strongylognathus testaceus</div> 
            </Link>

            <Link to="tapinoma-erraticum" className="kafelek">
                <img src={tapinomaErraticum} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>tapinoma erraticum</div> 
            </Link>

            <Link to="temnothorax-crassispinus" className="kafelek">
                <img src={temnothoraxCrassispinus} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>temnothorax crassispinus</div> 
            </Link>

            <Link to="tetramorium-caespitum" className="kafelek">
                <img src={tetramoriumCaespitum} onMouseOver={ () => console.log("onMouseOver") } onMouseOut={ () => console.log("onMouseOut") } />
                <div>tetramorium caespitum</div> 
            </Link>



        </div>
    )
}

export default MenuMrowkowe;