import React from 'react';
import {Link} from 'react-router-dom'
import formicaSanguinea from '../image/formica-sanguinea.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica sanguinea",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica sanguinea",
    Nazwa_polska: "zbójnica krwista",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "występuje na terenie całego kraju, z racji wymagań środowiskowych, umiarkowanie pospolita; w górach wypiera mrówki kopcowe;",
    Wielkosc_krolowej: "9 - 11 mm",
    Wielkosc_robotnic: "6 - 9 mm",
    Kolor: "czarno - rudy",
    Rojka: "lipiec - sierpień; upalne dni - kopulacja zachodzi często na kopcu, lub w jego pobliżu;",
    Zakladanie_gniazda: "pasożytnictwo czasowe (po rójce) lub podział kolonii (pleometrozja wtórna, pączkowanie) czasami połączony z wprowadzeniem królowej do gniazda podczas rajdu bądź odłączeniem od kolumny rajdowej grupy robotnic wraz z królową",
    Adopcja: "królowa wnika do gniazda Formica (Serviformica) spp., głównie Formica fusca zabijając po drodze robotnice. Po pewnym czasie przesiąka zapachem kolonii i robotnice zaczynają traktować ją jako własną królową, a dzięki silnym feromonom (charakterystyczne dla pasożytów), usuwa królową gospodarza. Nie zaleca się adopcji Formica rufibarbis, które mogą być agresywne w stosunku do świeżo wyklutych robotnic zbójnicy; nie należy również adoptować poczwarek z grupy Formica rufa - po wykluciu, robotnice dokonują emancypacji, zabijając królową.",
    Cechy_charakterystyczne: ["- mały, czarny odwłok, czasami z czerwoną plamą u nasady,",
        "- tułów rudy z delikatnym, czarnym rysunkiem i ciemniejszą kropką na przodzie,",
        "- duża, czarna głowa.",
        "- królowe są bardzo agresywne i szybkie", 
        "Jednoznacznie można ją zidentyfikowć po wcięciu w nadustku (patrz zdjęcia)."],
    Ciekawostki: 'Z racji na sposób zakładania gniazda, adopcja na robotnicach jest prawie niemożliwa, nawet po długotrwałym schłodzeniu. Dużym plusem jest to, że królowa potrafi otwierać poczwarki – z tego powodu najlepiej podrzucić jej kilka poczwarek, które na pewno zaakceptuje. Dojrzałej kolonii można podawać poczwarki innych Formica (Serviformica) spp. Nie podawać poczwarek rudnic! Podczas rajdów, robotnice idą szeroko rozstawioną falangą i po napotkaniu gniazda pierwomrówek "wlewają się" do środka. Nie atakują robotnic - w czasie ataku najprawdopodobniej rozsiewają substancje propagandowe z gruczołu' + "Dufore'a, które doprowadzają do wybuchu paniki w napadniętym gnieździe, dlatego pierwomrówki uciekają, aby przeczekać rajd. Zbójnice porywają głównie larwy, które służą jako pokarm dla własnego potomstwa - z porwanych poczwarek, czasami wylęgają się niewolnice. Jest to efekt niezamierzony, a robotnice zbójnicy są w pełni sprawne i mogą wykonywać wszelkie prace w gnieździe. Rajdy są przeprowadzane po rójce w najeżdżanej kolonii, ponieważ wtedy w gnieździe znajdują się larwy robotnic. Łatwo rozpoznać gatunek, jeśli w gnieździe zbójnic zauważy się niewolnice. Niewolnice stanowią z reguły jedynie kilka procent całej populacji gniazda i w zależności od gatunku, wykonują różnego rodzaju prace w gnieździe. Zdarza się nawet, że robotnice agresywniejszych gatunków wyruszają wraz ze zbójnicami na rajdy. W zależności od zagęszczenia występowania, kolonie mogą produkować duże, lub małe samice, różniące się wielkością oraz zasięgiem (duże - mały zasięg, małe - duży zasięg)",
    Gatunki_podobne: ["Rysunek na grzbiecie może przypominać Formica rufibarbis, która jednak ma znacznie większy odwłok i nieco inne ubarwienie. Ma też inne proporcje szerokości głowy i tułowia."],
    Trudnosc_hodowli: "Królowe są bardzo agresywne, co utrudnia adopcję (patrz: Strategia hodowli). Poza tym, gatunek łatwy w hodowli - rozwija się bardzo szybko - po kilku latach hodowli może to być problematyczne.",
    Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    Miejsce_gniazdowania: "lasy, zwłaszcza iglaste, często na obrzeżach; gniazda w spróchniałych pniakach (głównie), trawie, pod drzewami, także kamieniami (szczególnie w górach), tereny suche, nasłonecznione obrzeża lasu",
    Zachowanie: 'Mrówki z gatunku Formica sanguinea są bardzo agresywne i terytorialne, posiadają bardzo dobry wzrok. Eliminują każdy inny agresywny gatunek mrówek, który znajdzie się na ich terytorium. Nie wykazują dużej agresji w stosunku do oportunistycznych gatunków pierwomrówek - np. Formica fusca. Zbójnice krwiste praktykują dulozję fakultatywną ("niewolnictwo" fakultatywne). Gatunek ten organizuje rajdy na mrówki z podrodzaju Serviformica w celu porwania potomstwa (szczególnie poczwarek), które po wykluciu stają się częścią koloni porywaczy i zostają "niewolnikami". Mrówka drapieżna, odżywia się bardzo zróżnicowanym pokarmem zarówno białkowym, jak i energetycznym; aktywnie hoduje mszyce; w celu zapewnienia pokarmu białkowego, porywa potomstwo innych mrówek z podrodzaju Serviformica.',
    Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    Polimorfizm: "słaby polimorfizm - robotnice major mają dużą, ciemnoczerwoną głową; zbieraczki pojawiają się w koloniach liczących ponad 200 robotnic, majory pojawiają się w dużych koloniach, ponad 500 robotnic (szybciej w koloniach monoginicznych)",
    Liczebnosc: "kilka, do kilkunastu tysięcy robotnic, rzadko zdarzają się kolonie większe niż 12000 osobników",
    Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    Rozwoj_i_zimowanie: "rozwój jest bardzo szybki - królowa obficie czerwi, dzięki czemu kolonia rozwija się szybko; zimowanie konieczne na jesień, temp. 5C; królowa przestaje czerwić, a robotnice są mało aktywne.",
    Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    Metody_obrony: "bardzo sprawnie pryska kwasem, a także dotkliwie gryzie; dzięki dobremu wzrokowi, skutecznie broni gniazda a także źródeł pokarmu.",
    Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod częściową ochroną.",
    Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaSanguinea} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus