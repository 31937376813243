import React from 'react';
import {Link} from 'react-router-dom'
import formicaCinerea from '../image/formica-cinerea.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica cinerea",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica cinerea",
    Nazwa_polska: "pierwomrówka żwirowa",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "lasy, łąki, miasta",
    Wielkosc_krolowej: "9 - 11 mm",
    Wielkosc_robotnic: "4 - 7 mm",
    Kolor: "matowy czarny",
    Rojka: "czerwiec - koniec sierpnia",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Formica cinerea.",
    Cechy_charakterystyczne: ["- matowo czarna", "- duży, paskowany odwłok, mieniący się w świetle", "- lekko brązowe odnóża."],
    Ciekawostki: "Mrówka łatwo zakłada kolonię, poczwarki i robotnice są cennym źródłem w przypadku adopcji królowych pasożytniczych. Często spotykana w miastach, choć nie tak często jak Lasius niger. Dobra dla początkujących.",
    Gatunki_podobne: ["Formica fusca - drobniejsza, błyszcząco czarna, jednolity, błyszczący odwłok."],
    Zachowanie: "Mrówki agresywne i terytorialne, posiadające bardzo dobry wzrok. Drobne zdobycze zazwyczaj transportują samotnie do gniazda. W przypadku dużego źródła pokarmu szybko i sprawnie rekrutują inne robotnice.",
    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    // Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    Rozwoj_i_zimowanie: "Rozwój u tego gatunku jest bardzo szybki. Królowa rójkowa w ciągu miesiąca potrafi wychować pierwsze robotnice. Zimowanie bardzo dobrze wpływa na rozwój tych mrówek, jego brak może negatywnie wpłynąć na czerwienie królowej.",
    Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaCinerea} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus