import React from 'react';
import {Link} from 'react-router-dom'
import manicaRubida from '../image/manica-rubida.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Manica rubida",
    Rodzaj: "Manica",
    Nazwa_lacinska: "Manica rubida",
    Nazwa_polska: "wścieklica dorodna",
    Czestosc_wystepowania: "3/5",
    Wystepowanie: "Wyżyny oraz góry (również wysokie), południe kraju.",
    Wielkosc_krolowej: "10 - 11 mm",
    Wielkosc_robotnic: "9 - 10 mm",
    Kolor: "bursztynowy",
    Rojka: "Najczęściej dwie rójki rocznie: kwiecień oraz sierpień / wrzesień.",
    Zakladanie_gniazda: "Gatunek półklasztorny.",
    Adopcja: "Zalecana adopcja poczwarek, robotnice są agresywne nawet po długotrwałym schłodzeniu (robotnice chodzą w temperaturze lodówkowej).",
    Cechy_charakterystyczne: ["- duża (cecha kluczowa),",
    "- bursztynowa,",
    "- podwójny stylik,",
    "- duże żuwaczki z wieloma ząbkami (cecha kluczowa),",
    "- smukła, ale potężna."],
    Ciekawostki: "Manica rubida jest jedynym przedstawicielem swojego rodzaju w Europie. Mrówki tej z całą pewnością nie znajdziemy na nizinach. Użądlone miejsca mogą być zaczerwienione przez kilka dni - świeże użądlenie charakteryzuje się obrzękiem i pulsującym bólem. Kolonie często konkurują o pożywienie z Formica cinerea, jednak przeważnie nie wdają się w walki.",
    Gatunki_podobne: ["Z racji rozmiarów praktycznie nie sposób pomylić z niczym innym (dużo większa od Myrmica rubra)."],
    Trudnosc_hodowli: "Gatunek stosunkowo trudny w hodowli. Podstawową trudnością jest założenie kolonii - do rójkowych królowych zalecana jest adopcja poczwarek (łatwo je znaleźć pod kamieniami). Dobranie optymalnych warunków wilgotności, temperatury oraz pokarmu nie jest łatwe.",
    Strategia_hodowli: "Rójkową królową należy umieścić w formikarium z areną i dokarmiać, jednocześnie nie stresując jej zbytnio (dobrze jest zaciemnić probówkę). Polecana adopcja poczwarek (robotnice są zbyt agresywne i niewrażliwe na schłodzenie). Nie zaleca się łączenia królowych, ponieważ są po pewnym czasie eliminowane (patrz: zachowanie królowych w kolonii). Zalecane dogrzewanie",
    Miejsce_gniazdowania: "Kamieniste, nasłonecznione miejsca, głównie łąki i pastwiska; często również w miasteczkach, np. pod chodnikami; gniazda są przeważnie budowane pod kamieniami.",
    Zachowanie: "- agresywne, aczkolwiek nieprowokujące walk, - hodują mszyce i polują na drobne bezkręgowce, - robotnice poruszają się powoli, zaniepokojone biegną z uniesioną głową i rozpostartymi żuwaczkami, a w kontakcie dotkliwie żądlą.",
    Zachowanie_krolowych_w_kolonii: "Poliginia występuje u tego gatunku tylko w większych, polikalicznych koloniach, natomiast w tych małych królowe mogą się chwilowo tolerować, jednak są sukcesywnie eliminowane. Królowe tworzą wyraźną hierarchię, w której dominatorka osobiście eliminuje rywalki.",
    Polimorfizm: "Słaby, ale widoczny polimorfizm w dojrzałych koloniach.",
    Liczebnosc: "Przeważnie kilkaset, w koloniach polikalicznych kilka tysięcy robotnic.",
    Wilgotnosc_i_temperatura: "Duża wilgotność I dogrzewanie mają pozytywny wpływ na kolonię.",
    Rozwoj_i_zimowanie: "Rozwój stosunkowo powolny. Zdarza się, że robotnice zjadają potomstwo w przypadku złego doboru wilgotności bądź stresu. Zalecane zimowanie .",
    Wyglad_gniazda: "Często umiejscowione pod kamieniami, posiadają wiele wejść, z reguły bez kopca - wejścia mają formę krateru. Duże kolonie mają charakter polikaliczny i składają się z kilku połączonych gniazd.",
    Metody_obrony: "Żądło oraz twardy pancerz.",
    Aktywnosc_i_zasięg: "Umiarkowanie aktywne, robotnice nie oddalają się zbytnio od gniazda.",
    Klasyfikacja_systematyczna: "Myrmicinae, Myrmicini, Manica",
    // Oprzed_poczwarek: "przeważnie",
    Ochrona: "brak",
    Zrodla: ' "The ants (Hymenoptera, Formicidae) of Poland" - W. Czechowski, A. Radchenko, W. Czechowska, 2002, Warszawa',
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={manicaRubida} alt="Opis gatunku Dolichoderus quadripunctatus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus