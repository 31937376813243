import React from 'react';
import {Link} from 'react-router-dom'
import poneraCoarctata from '../image/ponera-coarctata.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Ponera coarctata",
    Rodzaj: "Ponera",
    Nazwa_lacinska: "Ponera coarctata",
    Nazwa_polska: "złośnica zwyczajna",
    Czestosc_wystepowania: "2/5",
    Wystepowanie: "Niziny i pojezierza z wyjątkiem Mazur, Podlasia oraz rejonu Sudetów i Beskidów zachodnich - częściej spotykana na południu Polski, ale i tam rzadka.",
    Wielkosc_krolowej: "4 mm",
    Wielkosc_robotnic: "3 - 4 mm",
    Kolor: "ciemnobrązowy",
    Rojka: "początek sierpnia – koniec września",
    Zakladanie_gniazda: "Półklasztorna - rójkowa królowa aktywnie poluje podczas początkowego etapu życia kolonii (typowo prymitywna cecha); zdarza się, że królowe wracają do gniazda macierzystego.",
    Adopcja: "Rójkową królową zaleca się zaadoptować robotnicami (ostrożnie, może być konieczne schłodzenie) bądź poczwarkami własnego gatunku.",
    Cechy_charakterystyczne: ["- niewielki rozmiar,",
    "- smukła budowa,",
    '- charakterystyczny kształt odwłoka - mocno wydłużony z "wcięciem" (cecha kluczowa) po pierwszym tergicie,',
    "- królowa od robotnicy różni się nieco większym tułowiem, śladami po skrzydłach, nieznacznie większym rozmiarem oraz przyoczkami,",
    "- widoczne żądło (cecha kluczowa)."],
    Ciekawostki: "Bardzo skryta mrówka, do tego rzadka, przez co nieczęsto spotykana. Charakterystyczny kształt sprawia, że nie sposób jej pomylić z żadną inną krajową mrówką. Poczwarki zawsze w oprzędzie, co jest typowe dla prymitywnego rodzaju Ponera.",
    Gatunki_podobne: ["Praktycznie nie do pomylenia."],
    Trudnosc_hodowli: "Wymagają ostrożnego dogrzewania. Gatunek trudny w hodowli, ponieważ jest słabo poznany i ciężko zapewnić im optymalne warunki. Patrz: Rozwój i zimowanie.",
    Strategia_hodowli: "Z reguły w hodowli spotyka się odłowione w całości kolonie; start od samotnej królowej jest rzadko spotykany i trudny.",
    Miejsce_gniazdowania: "Suche i średnio suche środowiska (kserofil), głównie piaszczyste łąki oraz lasy (przeważnie jasne). Ciepłolubne, chętnie gniazdują pod kamieniami lub mchem, często również w próchnicy i pod ściółką.",
    Zachowanie: "Robotnice chodzą ostrożnie i bardzo powolnie, nie rzucają się w oczy. Pomimo tego gatunek jest drapieżny i nie praktykuje hodowli mszyc. Robotnice furażerują parami, jedna za drugą.",
    Zachowanie_krolowych_w_kolonii: "Przeważnie monoginiczna, ale zdarzają się gniazda z kilkoma królowymi, które nie są względem siebie agresywne.",
    Polimorfizm: "Występują wyraźne kasty (polimorfizm).",
    Liczebnosc: "Kolonie są niewielkie - od kilkunastu do kilkudziesięciu robotnic.",
    Wilgotnosc_i_temperatura: "Preferują ciepłe otoczenie i niską wilgotność (kserofil).",
    Rozwoj_i_zimowanie: "Gatunek wykazuje powolny rozwój, mrówki często zjadają jaja i larwy. Prawdopodobnie wymagają zimowania.",
    Wyglad_gniazda: "Tworzą płytkie, mało rozbudowane gniazda. Zdarza się, że większe kolonie utworzą mocniej rozbudowane gniazdo (o promieniu około 10 cm). Wejście słabo widoczne, ukryte w ściółce.",
    Metody_obrony: "Mrówki te posiadają żądło.",
    Aktywnosc_i_zasięg: "Aktywne głównie za dnia. Robotnice furażerują pojedynczo, polując na drobne bezkręgowce w mchu i ściółce. Nie oddalają się zbytnio od gniazda.",
    Klasyfikacja_systematyczna: "Ponerinae, Ponerini, Ponera (Latreille)",
    // Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    Zrodla: ' "The ants (Hymenoptera, Formicidae) of Poland" - W. Czechowski, A. Radchenko, W. Czechowska, 2002, Warszawa',
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={poneraCoarctata} alt="Opis gatunku Ponera coarctata"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus