import React from 'react';
import {Link} from 'react-router-dom'
import myrmicaRubra from '../image/myrmica-rubra.jpg';

import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Myrmica rubra",
    Rodzaj: "Myrmica",
    Nazwa_lacinska: "Myrmica rubra",
    Nazwa_polska: "wścieklica zwyczajna",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "pospolita w całej Polsce",
    Wielkosc_krolowej: "5 - 7 mm",
    Wielkosc_robotnic: "3,5 - 5 mm",
    Kolor: "rudy",
    Rojka: "sierpień – wrzesień",
    Zakladanie_gniazda: "klasztorna, jednak wychów w pełni klasztorny trwa bardzo długo; możliwość wychowu półklasztornego",
    Adopcja: "W przypadku osierocenia, bądź do przyspieszenia rozwoju (zalecane w przypadku złapania samotnej królowej) - robotnice, bądź poczwarki tego samego gatunku.",
    Cechy_charakterystyczne: ["- średniego rozmiaru,",
        "- ruda,",
        "- podwójny stylik,",
        "- smukła,",
        "- odwłok jaśniejszy od reszty ciała,",
        "- w rodzaju Myrmica jeden z większych gatunków,",
        "- krótkie kolce epinotum (cecha kluczowa),",
        "- łagodnie wygięta nasada czułka, bez załamań, ani zgrubień (cecha kluczowa)"],
    Ciekawostki: "Często zakładają zawierające po kilka królowych gniazda pod kamieniami - z tego powodu po podniesieniu kamienia można odłowić królową z niewielkim orszakiem robotnic i rozpocząć od niej hodowlę. Dużą wadą jest niski poziom higieny utrzymywany w gnieździe. Poczwarki nie wytwarzają kokonów. Rójka zachodzi często na ziemi, w okolicy gniazda. Myrmica rubra posiada kilkanaście bardzo podobnych do siebie gatunków bliźniaczych. Gatunek ten potrafi wydawać na świat mikro-wersje królowych, które zachowują się jak inkwiliny, nie produkując robotnic, a jedynie formy płciowe, do czterdziestu razy więcej niż zwyczajne M. rubra. Odmiana ta jest bardzo rzadka - czasem znajdowana w centralnej Polsce.",
    Gatunki_podobne: ["inne gatunki z rodzaju Myrmica spp.", 
        "Temnothorax crassispinus – dużo mniejsza, wyraźny jasny pasek na odwłoku, mniej włosków, bardziej kontrastowa.",
        "Leptothorax acervorum – mniejsza, mniej owłosiona, ciemniejszy odwłok, wyraźnie smuklejsza, nieco bardziej kontrastowa."],
    Trudnosc_hodowli: "Problemem może być założenie kolonii przez samotną królową, dlatego zaleca się adopcję (najlepiej poczwarek) tego samego gatunku. Odłowione kolonie nie są wymagające i dobrze rozwijają się w szerokim zakresie dostępnych warunków.",
    Strategia_hodowli: "W przypadku znalezienia samotnej królowej, zalecana adopcja poczwarek, bądź młodych robotnic tego samego gatunku, lub regularne dokarmianie królowej. Najprostszym sposobem na rozpoczęcie hodowli jest odłowienie części dzikiej kolonii poliginicznej - taka kolonia jest prosta w hodowli.",
    Miejsce_gniazdowania: "Gniazduje w różnorodnych miejscach, od suchych do bardzo wilgotnych środowisk, ale preferuje wilgotne łąki i trawniki. Często spotykana w miastach, podobnie jak Myrmica rugulosa. Rzadziej spotykana w lasach, gdzie chętniej gniazduje jej bliźniaczka - Myrmica ruginodis.",
    Zachowanie: "Robotnice poruszają się powoli, natomiast zagrożone potrafią biegać i dotkliwie żądlić. Często furażerują nawet pomimo nieprzychylnej pogody - w chłodne i deszczowe dni. Adopcje w schłodzeniu mogą być utrudnione, ponieważ robotnice są aktywne nawet w temperaturach panujących w lodówce. Gatunek dość agresywny.",
    Zachowanie_krolowych_w_kolonii: "Poliginia występuje w koloniach o odpowiedniej liczbie robotnic - jeżeli jest ich za mało, nadmiarowe królowe zostaną wyeliminowane.",
    Polimorfizm: "brak",
    Liczebnosc: "kilka tysięcy robotnic, czasem ponad 10000",
    Wilgotnosc_i_temperatura: "Dobrze rozwijają się w średnio wilgotnych i wilgotnych gniazdach. Mogą być dogrzewane, ale nie muszą (rozwój jest szybki nawet bez dogrzewania).",
    Rozwoj_i_zimowanie: "Szybki, szczególnie w koloniach poliginicznych. Zalecane zimowanie. Można zimować razem z larwami i jajami.",
    Wyglad_gniazda: "Gniazda znajdują się głównie w wilgotnej ziemi, często pod kamieniami, kawałkami drewna itp. Wejście do gniazda z reguły jest zaopatrzone w ziemny kopczyk.",
    Metody_obrony: "Mrówki te posiadają żądło, którym potrafią dotkliwie żądlić (również człowieka).",
    Aktywnosc_i_zasięg: "Robotnice są aktywne nawet pomimo nieprzychylnej pogody - również w chłodne i deszczowe dni.",
    Klasyfikacja_systematyczna: "Myrmicinae, Myrmicini, Myrmica",
    // Oprzed_poczwarek: "przeważnie",
    Ochrona: "brak",
    Zrodla: '"The ants (Hymenoptera, Formicidae) of Poland" - W. Czechowski, A. Radchenko, W. Czechowska, 2002, Warszawa; J Evol Biol. 2006 May;19(3):1006.',
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={myrmicaRubra} alt="Opis gatunku Dolichoderus quadripunctatus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus