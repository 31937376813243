import React from 'react';
import {Link} from 'react-router-dom'
import strongylognathusTestaceus from '../image/strongylognathus-testaceus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Strongylognathus testaceus",
    Rodzaj: "Strongylognathus",
    Nazwa_lacinska: "Strongylognathus testaceus",
    Nazwa_polska: "sierpnica płowa",
    Czestosc_wystepowania: "2/5",
    // Wystepowanie: "lasy, parki",
    Wielkosc_krolowej: "3 - 4 mm",
    Wielkosc_robotnic: "2 mm",
    Kolor: "brązowy",
    Rojka: "lipiec - sierpień",
    Zakladanie_gniazda: "pasożyt (inkwilin)",
    Adopcja: "Tetramorium caespitum – pełna kolonia wraz z królową.",
    Cechy_charakterystyczne: ["- sierpowate żuwaczki,",
        '- głowa z „rogami”, ',
        "- kształt ciała nie do pomylenia,",
        "- przypomina dużą robotnicę murawki."],
    Ciekawostki: "Bardzo rzadka mrówka, praktykuje inkwilinizm. Adopcję przeprowadza się w dwóch etapach: - lodówkowa adopcja rójkowej królowej sierpnicy, rójkowej królowej Tetramorium caespitum (murawki) i kilku młodych robotnic murawki,     - dorzucenie kilkuset poczwarek murawki.     Obydwie królowe tolerują się nawzajem i równocześnie produkują potomstwo. Robotnice sierpnicy nie są zdolne do prac w gnieździe.",
    Gatunki_podobne: ["Nie do pomylenia."],
    // Trudnosc_hodowli: "Królowe są bardzo agresywne, co utrudnia adopcję (patrz: Strategia hodowli). Poza tym, gatunek łatwy w hodowli - rozwija się bardzo szybko - po kilku latach hodowli może to być problematyczne.",
    // Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    Miejsce_gniazdowania: "łąki, tereny piaszczyste",
    //Zachowanie: "Robotnice polują samotnie, gdy poczują się zagrożone bardzo szybko uciekają. Unikają konfrontacji z innymi mrówkami.",
    // Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    // Polimorfizm: "Występują wyraźne kasty (polimorfizm).",
    // Liczebnosc: "kilka, do kilkunastu tysięcy robotnic, rzadko zdarzają się kolonie większe niż 12000 osobników",
    // Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    // Rozwoj_i_zimowanie: "Gatunek ma tendencję do popadania w długie stagnacje. Młoda kolonia rozwija się bardzo powoli. Hibernacja zimowa jest niezbędna do prawidłowego rozwoju koloni.",
    // Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    // Metody_obrony: "Camponotus fallax zazwyczaj unika walki ratując się ucieczką. Mrówki tego gatunku potrafią pryskać kwasem.",
    // Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    // Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    Oprzed_poczwarek: "zawsze bez",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    //Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={strongylognathusTestaceus} alt="Opis gatunku Strongylognathus testaceus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus