import React from 'react';
import {Link} from 'react-router-dom'
import formicaTruncorum from '../image/formica-truncorum.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica truncorum",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica truncorum",
    Nazwa_polska: "mrówka pniakowa",
    Czestosc_wystepowania: "3/5",
    Wystepowanie: "głównie lasy",
    Wielkosc_krolowej: "9 - 11 mm",
    Wielkosc_robotnic: "6 - 10 mm",
    Kolor: "zmienny, odcienie czarnego, brązu i rudego",
    Rojka: "koniec czerwca – koniec sierpnia",
    Zakladanie_gniazda: "pasożyt",
    Adopcja: "Formica cinerea, Formica fusca, Formica rufibarbis, Formica cunicularia",
    Cechy_charakterystyczne: ["- mały, charakterystycznie ubarwiony odwłok (odcienie rudego włącznie z czarnym!),",
        "- tułów rudy z rysunkiem na tarczy,",
        "- głowa ruda z czarnym lub brązowym rysunkiem,",
        "- budową ciała przypomina rudnice, charakterystyczny jest kolor głowy i rysunek na tarczy."],
    Ciekawostki: "Mrówki nie wolno hodować – znajduje się pod ochroną ścisłą.",
    Gatunki_podobne: ["Formica sanguinea – czarna głowa, inne proporcje ciała.", "Formica rufa – czarna głowa oraz tarcza."],
    // Trudnosc_hodowli: "Królowe są bardzo agresywne, co utrudnia adopcję (patrz: Strategia hodowli). Poza tym, gatunek łatwy w hodowli - rozwija się bardzo szybko - po kilku latach hodowli może to być problematyczne.",
    // Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    // Miejsce_gniazdowania: "lasy, zwłaszcza iglaste, często na obrzeżach; gniazda w spróchniałych pniakach (głównie), trawie, pod drzewami, także kamieniami (szczególnie w górach), tereny suche, nasłonecznione obrzeża lasu",
    // Zachowanie: 'Mrówki z gatunku Formica sanguinea są bardzo agresywne i terytorialne, posiadają bardzo dobry wzrok. Eliminują każdy inny agresywny gatunek mrówek, który znajdzie się na ich terytorium. Nie wykazują dużej agresji w stosunku do oportunistycznych gatunków pierwomrówek - np. Formica fusca. Zbójnice krwiste praktykują dulozję fakultatywną ("niewolnictwo" fakultatywne). Gatunek ten organizuje rajdy na mrówki z podrodzaju Serviformica w celu porwania potomstwa (szczególnie poczwarek), które po wykluciu stają się częścią koloni porywaczy i zostają "niewolnikami". Mrówka drapieżna, odżywia się bardzo zróżnicowanym pokarmem zarówno białkowym, jak i energetycznym; aktywnie hoduje mszyce; w celu zapewnienia pokarmu białkowego, porywa potomstwo innych mrówek z podrodzaju Serviformica.',
    // Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    Polimorfizm: "występuje słaby polimorfizm",
    // Liczebnosc: "kilka, do kilkunastu tysięcy robotnic, rzadko zdarzają się kolonie większe niż 12000 osobników",
    // Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    // Rozwoj_i_zimowanie: "rozwój jest bardzo szybki - królowa obficie czerwi, dzięki czemu kolonia rozwija się szybko; zimowanie konieczne na jesień, temp. 5C; królowa przestaje czerwić, a robotnice są mało aktywne.",
    // Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    // Metody_obrony: "bardzo sprawnie pryska kwasem, a także dotkliwie gryzie; dzięki dobremu wzrokowi, skutecznie broni gniazda a także źródeł pokarmu.",
    // Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    // Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    // Oprzed_poczwarek: "przeważnie",
    Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    //Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaTruncorum} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus