import React from 'react';
import {Link} from 'react-router-dom'
import lasiusFuliginosus from '../image/lasius-fuliginosus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';


const daneMrowki = {
    Naglowek: "Lasius fuliginosus",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius fuliginosus",
    Nazwa_polska: "kartonówka zwyczajna, kartoniarka czarna",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "lasy, parki",
    Wielkosc_krolowej: "6 - 7 mm",
    Wielkosc_robotnic: "4 - 6 mm",
    Kolor: "smoliście czarny, błyszczący",
    Rojka: "początek czerwca – początek września",
    Zakladanie_gniazda: "pasożyt",
    Adopcja: "Lasius umbratus, najlepiej kokony z kilkoma robotnicami (nie umie otwierać kokonów).",
    Cechy_charakterystyczne: [" - czarny, błyszczący kolor",
        "- mocno wcięty tył głowy",
        "- mały odwłok."],
    Ciekawostki: "Mrówka ciekawa w obserwacji, robotnice mają interesujący wygląd. Adopcja podobna do adopcji Lasius umbratus. Adopcję kartonówki najlepiej przeprowadzać na młodych robotnicach Lasius umbratus i najlepiej ponad setki kokonów tegoż gatunku. Dorosłe robotnice podziemnicy nie mają chęci do zajmowania się królową. Według niepotwierdzonych informacji można adoptować na Lasius niger.",
    Gatunki_podobne: ["Lasius umbratus – zupełnie inny kolor (brązowy, możliwe różne odcienie)."],
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusFuliginosus} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus