import React from 'react';
import {Link} from 'react-router-dom'
import lasiusUmbratus from '../image/lasius-umbratus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Lasius umbratus",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius umbratus",
    Nazwa_polska: "podziemnica cieniolubna",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "łąki, lasy, tereny piaszczyste",
    Wielkosc_krolowej: "7 mm",
    Wielkosc_robotnic: "3 - 5 mm",
    Kolor: "brązowy do żółtego",
    Rojka: "koniec czerwca - początek września",
    Zakladanie_gniazda: "pasożyt",
    Adopcja: "Lasius niger, najlepiej kokony z kilkoma robotnicami (nie umie otwierać kokonów).",
    Cechy_charakterystyczne: ["- mocno wcięty tył głowy", "- jasne, żółtawe odnóża", "- mały odwłok", "- głowa szersza od tułowia", "- duże żuwaczki."],
    Ciekawostki: "Mrówka cenna w prywatnych hodowlach z uwagi na łatwość dostępu do robotnic w razie potrzeby adopcji królowej Lasius fuliginosus. Robotnice w naturze są bardzo skryte i trudno odkryć ich gniazdo, a co dopiero złapać robotnice do adopcji. Adopcję podziemnicy najlepiej przeprowadzać na 2 - 3 młodych robotnicach Lasius niger i kilkudziesięciu kokonach tegoż gatunku. Z naturalnego gniazda Lasius niger podbiera się kilkadziesiąt kokonów oraz kilka, najlepiej młodych, robotnic. Kokony umieszcza się w probówce z królową podziemnicy na kilka godzin, żeby przesiąkła ich zapachem, a następnie dodaje robotnice, które, wyczuwając zapach własnych poczwarek, nie atakują królowej. Wymaga dużej wilgotności gniazda. Lasius umbratus posiada kilka bardzo podobnych do siebie gatunków bliźniaczych.",
    Gatunki_podobne: ["Lasius fuliginosus – połyskująco czarna, jedynie końce odnóży żółtawe, nieco mniejsza głowa.", 
    "Lasius flavus – masywniejsza, znacznie większy odwłok, węższa głowa.",
    "Lasius brunneus – nieco ciemniejsza, masywniejsza (większy odwłok), delikatniejsze wcięcie tylnej krawędzi głowy, która jest równa szerokości tułowia."],

    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    // Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    // Metody_obrony: "Mrówki te pryskają kwasem mrówkowym."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusUmbratus} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus