import {Link} from 'react-router-dom'
import dolichoderusQuadripunctatus from '../image/dolichoderus-quadripunctatus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Dolichoderus quadripunctatus",
    Rodzaj: "Dolichoderus",
    Nazwa_lacinska: "Dolichoderus quadripunctatus",
    Nazwa_polska: "nadrzewnica czteroplamka, czterokropek",
    Czestosc_wystepowania: "3/5",
    Wystepowanie: "Centralna i południowa Polska; wszędzie rzadka bądź umiarkowanie rzadka.",
    Wielkosc_krolowej: "4,5 - 5 mm",
    Wielkosc_robotnic: "3,5 - 4,5 mm",
    Kolor: "czarny z akcentami czerwonego i żółtego, błyszczący",
    Rojka: "lipiec (główna rójka) – koniec września; królowe łatwiej znaleźć na drzewach niż na ziemi",
    Zakladanie_gniazda: "Półklasztorna - królowa aktywnie poluje podczas wychowywania pierwszego pokolenia robotnic.",
    Adopcja: "Zalecana adopcja młodych robotnic lub poczwarek (patrz: Strategia hodowli).",
    Cechy_charakterystyczne: ["- charakterystyczne ubarwienie, w tym cztery jasne plamki na odwłoku,",
        "- charakterystyczny profil tułowia oraz łuski pomostka."],
    Ciekawostki: "Rzadka mrówka, ceniona ze względu na interesujący wygląd i unikatowość. Niższe szczeblem królowe w poliginicznych koloniach zachowują się jak robotnice i niekiedy furażerują w poszukiwaniu pokarmu.",
    Gatunki_podobne: ["Plamki na odwłoku sprawiają, że nie sposób jej pomylić."],
    Trudnosc_hodowli: "Gatunek trudny w hodowli, bardzo trudny w przypadku rozpoczęcia hodowli od rójkowej królowej - zalecana jest adopcja. Królowa jest wrażliwa na stres, zaniepokojona może zjadać potomstwo, co utrudnia wychowanie pierwszych robotnic. Mrówki są wrażliwe na wiele czynników i łatwo padają. Królowa zaopatrywana w białko chętnie czerwi.",
    Strategia_hodowli: "W przypadku rójkowej królowej zaleca się adopcję poczwarek bądź młodych robotnic (piastunek). Duże, furażerujące robotnice nie będą zajmować się zaadoptowaną królową. Jeżeli nie ma możliwości adopcji, królową należy umieścić w zaciemnionej probówce z dostępem do areny zaopatrzonej w świeży pokarm. Niekiedy można wywabić jedną z królowych np. za pomocą kropli miodu - taką królową można odłowić z niewielkim orszakiem robotnic, aby rozpocząć kolonię.",
    Miejsce_gniazdowania: "W drewnie (dendrofil) - w martwych konarach i pniach drzew liściastych, rzadziej iglastych, czasem również w drewnianych konstrukcjach oraz w korze (mniejsze kolonie); nasłonecznione obszary leśne oraz parki.",
    Zachowanie: "Robotnice z reguły nie są agresywne, często zasiedlają drzewa sąsiadując z innymi gatunkami, np. Camponotus fallax.",
    Zachowanie_krolowych_w_kolonii: "Poliginiczne królowe tworzą hierarchię, w której królowe niższe szczeblem mogą zachowywać się jak robotnice (patrz: Ciekawostki). Zdarzają się też monoginiczne kolonie.",
    Polimorfizm: "Brak polimorfizmu, robotnice różnią się za to wielkością i zachowaniem.",
    Liczebnosc: "Kilkaset (150-500) osobników, rzadko spotyka się większe kolonie.",
    Wilgotnosc_i_temperatura: "Wymagana wysoka wilgotność, dobre efekty daje dogrzewanie.",
    Rozwoj_i_zimowanie: "Rozwój jest dosyć szybki, niestety często równoważy równie szybkie wymieranie robotnic. Mrówki nie wykazują skłonności do stagnacji. Zimowanie można przeprowadzić, aby skłonić królową do intensywniejszego czerwienia.",
    Wyglad_gniazda: "Gniazdo składa się z rozbudowanego systemu wąskich korytarzy w drewnie, posiada wiele wejść.",
    Metody_obrony: "Mrówki potrafią pryskać kwasem, aczkolwiek rzadko korzystają z tej metody obrony - w razie zagrożenia zamierają w bezruchu, a gdy sytuacja je do tego zmusi, uciekają szybkim krokiem, szukając zakamarków, w których mogą się ukryć. Wykazują wyśmienitą przyczepność do podłoża.",
    Aktywnosc_i_zasięg: "Licznie furażerują po pniu oraz gałęziach drzewa, które zamieszkują; rzadziej tworzą szlaki naziemne; hodują mszyce.",
    Klasyfikacja_systematyczna: "Dolichoderinae, Dolichoderini, Dolichoderus",
    Gatunki_blizniacze: "Występują u nas dwa podgatunki: nominalny oraz [D. quadripunctatus kratochvili], który charakteryzuje się ledwo widocznymi plamami na odwłoku.",
    Oprzed_poczwarek: "zawsze bez",
    Ochrona: 'Nie jest chroniony, ale jego status to "bliski zagrożenia".',
    Zrodla: '"The ants (Hymenoptera, Formicidae) of Poland" - W. Czechowski, A. Radchenko, W. Czechowska, 2002, Warszawa"',
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={dolichoderusQuadripunctatus} alt="Opis gatunku Dolichoderus quadripunctatus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus