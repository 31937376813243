import React from 'react';
import {Link} from 'react-router-dom'
import camponotusFallax from '../image/camponotus-fallax.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Camponotus fallax",
    Rodzaj: "Camponotus",
    Nazwa_lacinska: "Camponotus fallax",
    Nazwa_polska: "gmachówka pniowa, gmachówka zwodnicza",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "lasy, parki, miasta",
    Wielkosc_krolowej: "10 - 11 mm",
    Wielkosc_robotnic: "7 - 10 mm",
    Kolor: "czarny, błyszczący",
    Rojka: "początek maja – koniec czerwca",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Camponotus fallax.",
    Cechy_charakterystyczne: ["- smukła,",
        "- czarna,",
        "- czułki i odnóża jaśniejsze."],
    Ciekawostki: "Kolonia rozwija się bardzo powoli, konieczne zimowanie. Pomimo tego bardzo cenna, kasta żołnierzy wynagradza każdy trud hodowli. Średnie rozmiary mrówek znacznie ułatwiają obserwacje. Zakłada gniazda w drzewach. Drzewo z gniazdem można poznać po charakterystycznych wiórkach pod pniem. Warto obserwować gniazda podczas rójki.",
    Gatunki_podobne: ["Lasius fuliginosus – mniejszy odwłok, inny kształt ciała, inne ubarwienie odnóży.",
        "Formica fusca - szerszy tułów, inny kształt głowy oraz ciała.",
        "Camponotus herculeanus - akcenty brązu, dużo większa."],
    // Trudnosc_hodowli: "Królowe są bardzo agresywne, co utrudnia adopcję (patrz: Strategia hodowli). Poza tym, gatunek łatwy w hodowli - rozwija się bardzo szybko - po kilku latach hodowli może to być problematyczne.",
    // Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    // Miejsce_gniazdowania: "lasy, zwłaszcza iglaste, często na obrzeżach; gniazda w spróchniałych pniakach (głównie), trawie, pod drzewami, także kamieniami (szczególnie w górach), tereny suche, nasłonecznione obrzeża lasu",
    Zachowanie: "Robotnice polują samotnie, gdy poczują się zagrożone bardzo szybko uciekają. Unikają konfrontacji z innymi mrówkami.",
    // Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    Polimorfizm: "Występują wyraźne kasty (polimorfizm)",
    // Liczebnosc: "kilka, do kilkunastu tysięcy robotnic, rzadko zdarzają się kolonie większe niż 12000 osobników",
    // Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    // Rozwoj_i_zimowanie: "rozwój jest bardzo szybki - królowa obficie czerwi, dzięki czemu kolonia rozwija się szybko; zimowanie konieczne na jesień, temp. 5C; królowa przestaje czerwić, a robotnice są mało aktywne.",
    // Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    Metody_obrony: "Camponotus fallax zazwyczaj unika walki ratując się ucieczką. Mrówki tego gatunku potrafią pryskać kwasem.",
    // Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    // Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    // Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    //Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={camponotusFallax} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus