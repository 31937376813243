import React from 'react';
import {Link} from 'react-router-dom'
import formicaRufa from '../image/formica-rufa.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica rufa",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica rufa",
    Nazwa_polska: "mrówka rudnica",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "lasy",
    Wielkosc_krolowej: "9 - 11 mm",
    Wielkosc_robotnic: "4,5 - 9 mm",
    Kolor: "błyszczący, czarno-rudy",
    Rojka: "kwiecień - koniec czerwca",
    Zakladanie_gniazda: "pasożyt",
    Adopcja: "Formica cinerea, Formica fusca, Formica rufibarbis, Formica cunicularia",
    Cechy_charakterystyczne: ["- duża,", "- odwłok mały, czarny, z czerwoną plamą u nasady,", "- tułów rudy z czarną tarczą,", "- głowa czarna z rudymi bokami."],
    Ciekawostki: "Mrówki z tego gatunku bardzo szybko się mnożą i patrolują rozległe obszary. Mają również świetny wzrok i reagują bardzo agresywnie na ruch, tryskając kwasem mrówkowym. Znajduje się ona pod ochroną, więc nie należy ich odławiać, ani hodować.",
    Gatunki_podobne: ["Formica cunicularia - większy, matowy odwłok, ciemniejsze ubarwienie, sylwetka typowa dla podrodzaju Serviformica."],
    // Zachowanie: "Mrówki agresywne i terytorialne, posiadające bardzo dobry wzrok. Drobne zdobycze zazwyczaj transportują samotnie do gniazda. W przypadku dużego źródła pokarmu szybko i sprawnie rekrutują inne robotnice.",
    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    // Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    // Rozwoj_i_zimowanie: "Rozwój u tego gatunku jest bardzo szybki. Królowa rójkowa w ciągu miesiąca potrafi wychować pierwsze robotnice. Zimowanie bardzo dobrze wpływa na rozwój tych mrówek, jego brak może negatywnie wpłynąć na czerwienie królowej.",
    // Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym.",
    Ochrona: "Mrówka znajduje się pod częściową ochroną.",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaRufa} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus