import React from 'react';
import {Link} from 'react-router-dom'
import lasiusNiger from '../image/lasius-niger.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Lasius niger",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius niger",
    Nazwa_polska: "hurtnica czarna",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "miasta, łąki, lasy, wszędzie",
    Wielkosc_krolowej: "8 - 9 mm",
    Wielkosc_robotnic: "3 - 4 mm",
    Kolor: "ciemnobrązowy, aksamitny",
    Rojka: "koniec czerwca - początek września",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Lasius niger.",
    Cechy_charakterystyczne: ["- aksamitny połysk","- wcięty tył głowy", "- brunatne odnóża", "- czekoladowy tułów i odwłok."],
    Ciekawostki: "Najczęstsza i najprostsza w hodowli mrówka. Masowe rójki (możliwość zebrania nawet do 20 zaplemnionych królowych na minutę – czego jednak nie należy robić!) sprawiają, że znalezienie królowej jest banalnie proste. Z tego powodu dobra dla nowicjuszy.",
    Gatunki_podobne: ["Lasius emarginatus – różni się kolorem tułowia i delikatnym rysunkiem, nieco szersza głowa.",
        "Lasius flavus – jaśniejsza od Lasius niger, żółte odnóża i spód odwłoka.",
        "Lasius brunneus – nieco jaśniejsza, smuklejsza i drobniejsza, głowa szerokości tułowia, tułów mniejszy w stosunku do ciała niż u Lasius niger."],
    Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    
    Metody_obrony: "Mrówki te pryskają kwasem mrówkowym."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusNiger} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus