import React from 'react';
import {Link} from 'react-router-dom'
import formicaFusca from '../image/formica-fusca.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Formica fusca",
    Rodzaj: "Formica",
    Nazwa_lacinska: "Formica fusca",
    Nazwa_polska: "pierwomrówka łagodna",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "łąki, lasy",
    Wielkosc_krolowej: "8 - 10 mm",
    Wielkosc_robotnic: "3,5 - 7,5 mm",
    Kolor: "błyszcząco czarna",
    Rojka: "czerwiec - koniec sierpnia",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Formica fusca.",
    Cechy_charakterystyczne: ["Błyszcząco czarna."],
    Ciekawostki: "Mrówka łatwo zakłada kolonię, poczwarki i robotnice są cennym źródłem w przypadku adopcji królowych pasożytniczych. Dobra dla początkujących. Poliginiczna.",
    Gatunki_podobne: ["Formica cunicularia - brązowy spód tułowia.", "Formica cinerea - masywniejsza, omszona. Większy, wyraźnie paskowany odwłok."],
    // Zachowanie: "Mrówki agresywne i terytorialne, posiadające bardzo dobry wzrok. Drobne zdobycze zazwyczaj transportują samotnie do gniazda. W przypadku dużego źródła pokarmu szybko i sprawnie rekrutują inne robotnice.",
    // Trudnosc_hodowli: "Idealna mrówka dla początkującego, bardzo odporna na błędy i dość szybko się mnoży.",
    // Liczebnosc: "Liczba robotnic w koloni może dochodzić do 10 000.",
    // Rozwoj_i_zimowanie: "Rozwój u tego gatunku jest bardzo szybki. Królowa rójkowa w ciągu miesiąca potrafi wychować pierwsze robotnice. Zimowanie bardzo dobrze wpływa na rozwój tych mrówek, jego brak może negatywnie wpłynąć na czerwienie królowej.",
    // Metody_obrony: "Gatunek ten potrafi pryskać kwasem mrówkowym."
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={formicaFusca} alt="Opis gatunku Lasius brunneus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus