import React from 'react';
import {Link} from 'react-router-dom'
import lasiusFlavus from '../image/lasius-flavus.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Lasius flavus",
    Rodzaj: "Lasius",
    Nazwa_lacinska: "Lasius flavus",
    Nazwa_polska: "podziemnica zwyczajna",
    Czestosc_wystepowania: "4/5",
    Wystepowanie: "łąki, lasy",
    Wielkosc_krolowej: "7 - 9 mm",
    Wielkosc_robotnic: "2,5 - 4 mm",
    Kolor: "brązowy, żółte odnóża i spód odwłoka, aksamitny",
    Rojka: "koniec czerwca - początek września",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Lasius flavus.",
    Cechy_charakterystyczne: ["- aksamitny połysk", "- wcięty tył głowy", "- żółte odnóża", "- brązowy tułów i odwłok", "- żółty spód odwłoka", "- kształtem przypomina Lasius niger"],
    Ciekawostki: "W przeciwieństwie do Lasius umbratus, buduje kopce z ziemi lub piasku. Ciekawa w hodowli ze względu na nietypowy kolor i stosunkową rzadkość. Wilgociolubna.",
    Gatunki_podobne: ["Lasius niger – ciemniejsza, odnóża wyraźnie brązowe.",
        "Lasius brunneus – odnóża jasnobrązowe, mniejszy tułów, spód odwłoka brązowy.",
        "Lasius umbratus – wyraźnie mniejszy odwłok, głowa bardziej wcięta i szersza.</dd>"],
    Miejsce_gniazdowania: "",
    Zachowanie: "",
    Polimorfizm: "",
    Metody_obrony: ""
}


const LasiusFlavus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={lasiusFlavus} alt="Opis gatunku Lasius flavus"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusFlavus