import React from 'react';
import {Link} from 'react-router-dom'
import tetramoriumCaespitum from '../image/tetramorium-caespitum.jpg';
import WspolnySzablonGatunku from './wspolny_szablon';

const daneMrowki = {
    Naglowek: "Tetramorium caespitum",
    Rodzaj: "Camponotus",
    Nazwa_lacinska: "Tetramorium caespitum",
    Nazwa_polska: "murawka darniowiec",
    Czestosc_wystepowania: "5/5",
    Wystepowanie: "miasta, łąki, lasy",
    Wielkosc_krolowej: "7 mm",
    Wielkosc_robotnic: "2 - 4 mm",
    Kolor: "czarny, błyszczący",
    Rojka: "początek czerwca – koniec sierpnia (jedne z pierwszych po gmachówkach)",
    Zakladanie_gniazda: "klasztorna",
    Adopcja: "W przypadku osierocenia - Tetramorium caespitum.",
    Cechy_charakterystyczne: ["- podwójny stylik,",
        "- smukła,",
        "- błyszcząco czarna,",
        "- dość nieduża."],
    Ciekawostki: "Częsta i prosta w hodowli mrówka. Niewielkie rozmiary i ciekawy wygląd robotnic mogą być urozmaiceniem domowej hodowli. Chętnie żywi się nasionami, szczególnie zbóż, ryżu i np. dmuchawca. Poczwarki nie wytwarzają kokonów.",
    Gatunki_podobne: ["Solenopsis fugax - nieco mniejsza, inne proporcje głowy i tułowia do odwłoka, żółty spód odwłoka."],
    Trudnosc_hodowli: "Gatunek banalnie prosty w hodowli, polecany dla początkujących. Bardzo szybko się rozmnaża.",
    // Strategia_hodowli: "Królowe zbójnicy potrafią odżywiać się same, niestety wymagają adopcji niewolnic, aby założyć kolonię. Z racji bardzo wysokiej agresji, adopcja robotnic jest wysoce problematyczna. Królowa sprawnie otwiera poczwarki pierwomrówek, więc najprostszą metodą jest adopcja (minimum kilkunastu) poczwarek Formica fusca, Formica cunicularia, bądź Formica cinerea.",
    // Miejsce_gniazdowania: "lasy, zwłaszcza iglaste, często na obrzeżach; gniazda w spróchniałych pniakach (głównie), trawie, pod drzewami, także kamieniami (szczególnie w górach), tereny suche, nasłonecznione obrzeża lasu",
    Zachowanie: "Mrówka odważna i wojownicza. Nie należy jej łączyć na arenie z żadnym innym gatunkiem ponieważ Tetramorium caespitum szybko zajmie się eliminowaniem konkurencji.",
    // Zachowanie_krolowych_w_kolonii: "W koloniach panuje monoginia funkcjonalna (może występować więcej królowych, ale tylko liderka czerwi); królowa gospodarza jest usuwana na zasadzie wojny feromonowej. Królowe po rójce mogą być przyjmowane do gniazda macierzystego, jednak tego samgo, lub następnego roku na wiosnę następuje podział (usunięcie nadmiarowych królowych).",
    // Polimorfizm: "Występują wyraźne kasty (polimorfizm).",
    Liczebnosc: "W dorosłej koloni może być maksymalnie 50 000 osobników",
    // Wilgotnosc_i_temperatura: "wilgotność średnia, lub niska; lubi wygrzewać poczwarki",
    // Rozwoj_i_zimowanie: "Gatunek ma tendencję do popadania w długie stagnacje. Młoda kolonia rozwija się bardzo powoli. Hibernacja zimowa jest niezbędna do prawidłowego rozwoju koloni.",
    // Wyglad_gniazda: "u mniejszych kolonii wygląda jak wejście do gniazda gospodarza, natomiast u dużych kolonii wyposażone w kopiec z igliwia, lub rzadziej z ziemi; z reguły umiejscowione w nasłonecznionym miejscu, w pobliżu drzew, często na bazie spróchniałego pniaka; wokół gniazda centralnego można znaleźć gniazda satelitarne (często jako inkubatory pod kamieniami)",
    Metody_obrony: "Mrówki Tetramorium caespitum posiadają żądło, które jest zbyt słabe by przebić skórę człowieka.",
    // Aktywnosc_i_zasięg: "aktywna podczas dnia, głównie w suche, gorące dni; robotnice potrafią furażerować na kilkadziesiąt metrów, tworząc szerokie szlaki pokarmowe.",
    // Klasyfikacja_systematyczna: "Formicinae, Formiciini, Formica (Raptiformica)",
    // Oprzed_poczwarek: "przeważnie",
    //Ochrona: "Mrówka znajduje się pod ścisłą ochroną.",
    //Zrodla: "The ants (Hymenoptera, Formicidae) of Poland[/i] - W. Czechowski, A. Radchenko, W. Czechowska; Warszawa 2002 Obserwacje BartTP",
}

const LasiusBrunneus = () => {

    return (
        <>
            <Link to="/gatunek" className="kafelek">
                <img src={tetramoriumCaespitum} alt="Opis gatunku Tetramorium caespitum"/>
                <div>Wróć</div>
            </Link>

            <WspolnySzablonGatunku daneMrowki={daneMrowki} />
        </>
    );
}

export default LasiusBrunneus